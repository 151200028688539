
import TakeUserPictureDTO from "@/dtos/TakeUserPictureDTO";
import { PropType, defineComponent, reactive } from "vue";

interface State {
    selectedIndex: number;
}
const PhotosAlertList = defineComponent({
    props: {
        alerts: {
            type: Array as PropType<TakeUserPictureDTO[]>
        }
    },
    emits: ['onPhotoClicked'],
    setup(props, { emit }) {
        const state = reactive<State>({
            selectedIndex: 0
        });

        const onAlertClicked = (index: number) => {
            emit("onPhotoClicked", index);
            state.selectedIndex = index;
        }

        return {
            onAlertClicked, 
            state
        }
    }
});

export default PhotosAlertList;
