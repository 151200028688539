
import Icon from "@/components/Icon.vue";
import Modal from "@/components/Modal.vue";
import Switch from "@/components/ToogleSwitch.vue";
import { SensibilityEnum } from "@/dtos/ConfigsDTO";
import { AlertCategoryEnum, RulesDTO } from "@/dtos/RulesDTO";
import { useAlert } from '@/hooks/alert';
import ProctoringService from "@/services/ProctoringService";
import {
    computed,
    defineComponent,
    reactive,
    watch
} from "vue";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Dropdown from "./Dropdown.vue";

interface ConfigureMediaModalState {
    faceChecked: boolean;
    personChecked: boolean;
    phoneChecked: boolean;
    noiseChecked: boolean;
    speechChecked: boolean;
    warningsChecked: boolean;

    sensibility: SensibilityEnum;

    sensibilityNum: number;
    sensibilityLastValue: number;

    rulesNum: number;
    rules: RulesDTO[];
}

export const PROCESSING_OPTIONS = [
    {
        label: "SENSÍVEL",
        value: SensibilityEnum.SENSITIVE,
    },
    {
        label: "REGULAR",
        value: SensibilityEnum.REGULAR,
    },
    {
        label: "PERMISSIVO",
        value: SensibilityEnum.PERMISSIVE,
    },
];

export const RULES_OPTIONS = [
    {
        label: "Menos rígido",
        value: 0,
        rules: [
            {
                id: "17aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "NoFace",
                type: 0,
                quantity: 5,
                duration: 2000,
                description: ' ',
            },
            {
                id: "18aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "MultipleFace",
                type: 0,
                quantity: 5,
                duration: 2000,
                description: ' ',
            },
            {
                id: "19aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "OtherFace",
                type: 0,
                quantity: 5,
                duration: 2000,
                description: ' ',
            },
            {
                id: "20aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "HeadUp",
                type: 0,
                quantity: 5,
                duration: 2000,
                description: ' ',
            },
            {
                id: "21aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "HeadDown",
                type: 0,
                quantity: 5,
                duration: 2000,
                description: ' ',
            },
            {
                id: "22aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "HeadRight",
                type: 0,
                quantity: 5,
                duration: 2000,
                description: ' ',
            },
            {
                id: "23aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "HeadLeft",
                type: 0,
                quantity: 5,
                duration: 2000,
                description: ' ',
            },
            {
                id: "24aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "CellPhone",
                type: 0,
                quantity: 5,
                duration: 2000,
                description: ' ',
            },
            {
                id: "25aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "EnvironmentMultiplePeople",
                type: 0,
                quantity: 5,
                duration: 2000,
                description: ' ',
            },
            {
                id: "26aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "EnvironmentNoPeople",
                type: 0,
                quantity: 5,
                duration: 2000,
                description: ' ',
            },
            {
                id: "27aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "Badword",
                type: 0,
                quantity: 3,
                duration: 0,
                description: "resposta",
            },
            {
                id: "28aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "Badword",
                type: 0,
                quantity: 3,
                duration: 0,
                description: "prova",
            },
            {
                id: "29aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "Badword",
                type: 0,
                quantity: 3,
                duration: 0,
                description: "ajuda",
            },
            {
                id: "30aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "Speech",
                type: 0,
                quantity: 5,
                duration: 2000,
                description: ' ',
            },
        ]
    },
    {
        label: "Rígido",
        value: 1,
        rules: [
            {
                id: "1aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "NoFace",
                type: 0,
                quantity: 3,
                duration: 2000,
                description: ' ',
            },
            {
                id: "d6ebd07f-ad45-4be1-b081-a3c041cb0fac",
                category: "MultipleFace",
                type: 0,
                quantity: 3,
                duration: 2000,
                description: ' ',
            },
            {
                id: "3aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "OtherFace",
                type: 0,
                quantity: 3,
                duration: 2000,
                description: ' ',
            },
            {
                id: "4aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "HeadUp",
                type: 0,
                quantity: 3,
                duration: 2000,
                description: ' ',
            },
            {
                id: "5aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "HeadDown",
                type: 0,
                quantity: 3,
                duration: 2000,
                description: ' ',
            },
            {
                id: "6aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "HeadRight",
                type: 0,
                quantity: 3,
                duration: 2000,
                description: ' ',
            },
            {
                id: "7aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "HeadLeft",
                type: 0,
                quantity: 3,
                duration: 2000,
                description: ' ',
            },
            {
                id: "8aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "CellPhone",
                type: 0,
                quantity: 3,
                duration: 2000,
                description: ' ',
            },
            {
                id: "9aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "EnvironmentMultiplePeople",
                type: 0,
                quantity: 3,
                duration: 2000,
                description: ' ',
            },
            {
                id: "10aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "EnvironmentNoPeople",
                type: 0,
                quantity: 3,
                duration: 2000,
                description: ' ',
            },
            {
                id: "11aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "Badword",
                type: 0,
                quantity: 2,
                duration: 0,
                description: "resposta",
            },
            {
                id: "12aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "Badword",
                type: 0,
                quantity: 2,
                duration: 0,
                description: "prova",
            },
            {
                id: "13aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "Badword",
                type: 0,
                quantity: 2,
                duration: 0,
                description: "ajuda",
            },
            {
                id: "14aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "Speech",
                type: 0,
                quantity: 3,
                duration: 2000,
                description: ' ',
            },
        ]
    },
    {
        label: "Muito rígido",
        value: 2,
        rules: [
            {
                id: "31aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "NoFace",
                type: 0,
                quantity: 2,
                duration: 2000,
                description: ' ',
            },
            {
                id: "32aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "MultipleFace",
                type: 0,
                quantity: 2,
                duration: 2000,
                description: ' ',
            },
            {
                id: "33aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "OtherFace",
                type: 0,
                quantity: 2,
                duration: 2000,
                description: ' ',
            },
            {
                id: "34aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "HeadUp",
                type: 0,
                quantity: 2,
                duration: 2000,
                description: ' ',
            },
            {
                id: "35aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "HeadDown",
                type: 0,
                quantity: 2,
                duration: 2000,
                description: ' ',
            },
            {
                id: "36aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "HeadRight",
                type: 0,
                quantity: 2,
                duration: 2000,
                description: ' ',
            },
            {
                id: "37aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "HeadLeft",
                type: 0,
                quantity: 2,
                duration: 2000,
                description: ' ',
            },
            {
                id: "38aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "CellPhone",
                type: 0,
                quantity: 2,
                duration: 2000,
                description: ' ',
            },
            {
                id: "39aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "EnvironmentMultiplePeople",
                type: 0,
                quantity: 2,
                duration: 2000,
                description: ' ',
            },
            {
                id: "40aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "EnvironmentNoPeople",
                type: 0,
                quantity: 2,
                duration: 2000,
                description: ' ',
            },
            {
                id: "41aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "Badword",
                type: 0,
                quantity: 1,
                description: "resposta",
                duration: 0,
            },
            {
                id: "42aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "Badword",
                type: 0,
                quantity: 1,
                description: "prova",
                duration: 0,
            },
            {
                id: "43aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "Badword",
                type: 0,
                quantity: 1,
                description: "ajuda",
                duration: 0,
            },
            {
                id: "44aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "Speech",
                type: 0,
                quantity: 2,
                duration: 2000,
                description: ' ',
            },
        ]
    },
];

export const ALERT_CATEGORIES = [
    {
        description: "Nenhuma face",
        type: "NoFace",
        value: 1,
    },
    {
        description: "Várias faces",
        type: "MultipleFace",
        value: 2,
    },
    {
        description: "Face de outra pessoa",
        type: "OtherFace",
        value: 3,
    },
    {
        description: "Cabeça para cima",
        type: "HeadUp",
        value: 10,
    },
    {
        description: "Cabeça para baixo",
        type: "HeadDown",
        value: 11,
    },
    {
        description: "Cabeça para direita",
        type: "HeadRight",
        value: 12,
    },
    {
        description: "Cabeça para esquerda",
        type: "HeadLeft",
        value: 13,
    },
    // {
    //     description: "Cabeça inclinada",
    //     type: "HeadRolled",
    //     value: 14,
    // },
    {
        description: "Detecção de celular",
        type: "CellPhone",
        value: 27,
    },
    {
        description: "Várias pessoas",
        type: "EnvironmentMultiplePeople",
        value: 28,
    },
    {
        description: "Nenhuma pessoa",
        type: "EnvironmentNoPeople",
        value: 29,
    },
    {
        description: "Palavra inadequada",
        type: "Badword",
        value: 96,
    },
    {
        description: "Detecção de conversa",
        type: "Speech",
        value: 97,
    },
    {
        description: "Detecção de ruído",
        type: "Noise",
        value: 98,
    },
    {
        description: "Sem alerta",
        type: "NoAlert",
        value: 100,
    },
];

const ConfigModal = defineComponent({
    components: { Icon, Modal, Dropdown, Switch },
    emits: ['toogleModal'],
    setup(props, context) {
        const defineColorByClient = computed(() => {
            return `#04986D`;
        });

        const alert = useAlert();

        const translateSensibility = computed(() => {
            return PROCESSING_OPTIONS.find((item) => item.value === state.sensibility)
                ?.label;
        });

        // const usedCategories = computed(() => {
        //     return items.map((item: any) => item.category);
        // });

        const availableCategories = computed(() => {
            return ALERT_CATEGORIES; //.filter((alert) => !usedCategories.value.includes(alert.value));
        });

        const sensibilityToNumber = () => {
            switch (state.sensibility) {
                case SensibilityEnum.PERMISSIVE:
                    return 0;
                case SensibilityEnum.REGULAR:
                    return 1;
                case SensibilityEnum.SENSITIVE:
                    return 2;
            }
        };

        const alertSelected = (value: number) => {
            return ALERT_CATEGORIES.find((item) => item.value === value);
        };

        const status = reactive<any>({
            selectMediaModalOpened: false,
        });

        const state = reactive<ConfigureMediaModalState>({
            faceChecked: false,
            personChecked: false,
            phoneChecked: false,
            noiseChecked: false,
            speechChecked: false,
            warningsChecked: false,

            sensibility: SensibilityEnum.REGULAR,
            sensibilityNum: 0,
            sensibilityLastValue: 0,

            rulesNum: -1,
            rules: [],
        });

        const items = reactive<any>([
            // { category: '', quantity: 0, duration: 0, description: 'teste da descrição' }
        ]);

        // Convert category type to value
        function mapCategory(category: string | AlertCategoryEnum) {
            const foundCategory = ALERT_CATEGORIES.find(
                (item) => item.type === category
            );
            return foundCategory ? foundCategory.value : category;
        }

        // Fill all descriptions on items
        const adjustDescriptions = () => {
            return items.map((item: any) => {
                return {
                    ...item,
                    duration: item.duration < 100 ? item.duration * 1000 : item.duration,
                    // description: alertSelected(item.category)?.description,
                };
            });
        };

        // GET Configs
        const populateFields = () => {
            items.forEach((item: any, index: any) => removeItem(index));

            const [request] = ProctoringService.GetConfigs();
            request
                .then((resp) => {
                    state.phoneChecked = resp.videoBehaviourParameters?.detectCellPhone;
                    state.personChecked = resp.videoBehaviourParameters?.detectPerson;
                    state.faceChecked = resp.videoBehaviourParameters?.detectFace;
                    state.noiseChecked = resp.videoBehaviourParameters?.detectNoise;
                    state.speechChecked = resp.videoBehaviourParameters?.detectSpeech;
                    state.warningsChecked =
                        resp.videoBehaviourParameters?.showWarningAlerts;

                    state.sensibility = SensibilityEnum[resp.imageBehaviourParameters?.sensibility];

                    state.sensibilityNum = sensibilityToNumber();
                    state.sensibilityLastValue = sensibilityToNumber();
                })
                .catch((error) => console.log(error));

            const [request2] = ProctoringService.GetRules();
            request2
                .then((resp) => {
                    state.rules = resp;

                    items.splice(0, items.length);

                    resp.forEach(({ category, quantity, duration, description, id }) => {
                        const alreadyInList = items.find((item: any) => item.id === id);
                        if (!alreadyInList) {
                            items.push({
                                category: mapCategory(category),
                                quantity,
                                duration: duration / 1000,
                                description,
                                id,
                            });
                        }
                    });
                })
                .catch((error) => console.log(error));
        };

        const addItem = () => {
            items.push({ category: "", quantity: 0, duration: 0, description: "" });
        };

        const removeItem = (index: any) => {
            items.splice(index, 1);
        };

        const saveActiveMonitoring = () => {
            const [request] = ProctoringService.PostActiveMonitoring({
                videoBehaviourParameters: {
                    detectCellPhone: state.phoneChecked,
                    detectPerson: state.personChecked,
                    detectFace: state.faceChecked,
                    detectNoise: state.noiseChecked,
                    detectSpeech: state.speechChecked,
                    showWarningAlerts: state.warningsChecked,
                },
            });

            return request
                .then((resp) => {
                    // console.log(resp);
                    toast("Modificação realizada!", {
                        theme: "colored",
                        type: "success",
                        position: "top-center",
                        transition: "slide",
                        dangerouslyHTMLString: true
                    });
                })
                .catch((error) => {
                    toast("Modificação falhou!", {
                        theme: "colored",
                        type: "error",
                        position: "top-center",
                        transition: "slide",
                        dangerouslyHTMLString: true
                    });

                    throw new Error(error);
                });
        };

        const saveProcessing = () => {
            const [request] = ProctoringService.PostProcessing({
                sensibility: state.sensibility,
            });

            return request
                .then((resp) => {
                    // console.log(resp);
                    toast("Modificação realizada!", {
                        theme: "colored",
                        type: "success",
                        position: "top-center",
                        transition: "slide",
                        dangerouslyHTMLString: true
                    });
                })
                .catch((error) => {
                    toast("Modificação falhou!", {
                        theme: "colored",
                        type: "error",
                        position: "top-center",
                        transition: "slide",
                        dangerouslyHTMLString: true
                    });

                    throw new Error(error);
                });
        };

        const saveRules = async () => {
            const adjustedItems = adjustDescriptions();
            const [request] = ProctoringService.PostRules(adjustedItems);

            const confirmResult = await window.confirm('Você tem certeza que deseja alterar esse parâmetro?');

            if (confirmResult) {
                return request
                    .then((resp) => {
                        // console.log(resp);
                        toast("Modificação realizada!", {
                            theme: "colored",
                            type: "success",
                            position: "top-center",
                            transition: "slide",
                            dangerouslyHTMLString: true
                        });
                    })
                    .catch((error) => {
                        toast("Modificação falhou!", {
                            theme: "colored",
                            type: "error",
                            position: "top-center",
                            transition: "slide",
                            dangerouslyHTMLString: true
                        });
                    });
            } else {
                toast("Operação cancelada!", {
                    theme: "colored",
                    type: "warning",
                    position: "top-center",
                    transition: "slide",
                    dangerouslyHTMLString: true
                });
            }

        };

        const toogleModal = () => {
            context.emit('toogleModal')
        };

        populateFields();

        watch(() => state.rulesNum, () => {
            if (state.rulesNum === -1) {
                const [request] = ProctoringService.GetRules();
                request
                    .then((resp) => {
                        state.rules = resp;

                        items.splice(0, items.length);

                        resp.forEach(({ category, quantity, duration, description, id }) => {
                            const alreadyInList = items.find((item: any) => item.id === id);
                            if (!alreadyInList) {
                                items.push({
                                    category: mapCategory(category),
                                    quantity,
                                    duration: duration / 1000,
                                    description,
                                    id,
                                });
                            }
                        });
                    })
                    .catch((error: any) => console.log(error));
            } else {
                items.splice(0, items.length);

                const selectedOption = RULES_OPTIONS.find(option => option.value === state.rulesNum);

                selectedOption?.rules.forEach(({ category, quantity, duration, description, id }) => {
                    const alreadyInList = items.find((item: any) => item.id === id);
                    if (!alreadyInList) {
                        items.push({
                            category: mapCategory(category),
                            quantity,
                            duration: duration / 1000,
                            description,
                            id,
                        });
                    }
                });

            }
        });


        async function changeSwitch(type: string) {
            const confirmResult = await window.confirm('Você tem certeza que deseja alterar esse parâmetro?');

            try {
                if (confirmResult) {
                    await saveActiveMonitoring();
                } else {
                    toast("Operação cancelada!", {
                        theme: "colored",
                        type: "warning",
                        position: "top-center",
                        transition: "slide",
                        dangerouslyHTMLString: true
                    });
                    throw "negado";
                }
            } catch (e) {
                switch (type) {
                    case "face":
                        state.faceChecked = !state.faceChecked;
                        break;
                    case "person":
                        state.personChecked = !state.personChecked;
                        break;
                    case "phone":
                        state.phoneChecked = !state.phoneChecked;
                        break;
                    case "noise":
                        state.noiseChecked = !state.noiseChecked;
                        break;
                    case "speech":
                        state.speechChecked = !state.speechChecked;
                        break;
                    case "warning":
                        state.warningsChecked = !state.warningsChecked;
                        break;
                }
            }
        }

        async function handleSensibility(event: any) {
            const confirmResult = await window.confirm('Você tem certeza que deseja alterar esse parâmetro?');

            try {
                if (confirmResult) {
                    const inputValue = Number(event.target.value);
                    switch (inputValue) {
                        case 0:
                            state.sensibility = SensibilityEnum.PERMISSIVE;
                            break;
                        case 1:
                            state.sensibility = SensibilityEnum.REGULAR;
                            break;
                        case 2:
                            state.sensibility = SensibilityEnum.SENSITIVE;
                            break;
                    }

                    await saveProcessing();

                    state.sensibilityNum = inputValue;
                    state.sensibilityLastValue = inputValue;
                } else {
                    toast("Operação cancelada!", {
                        theme: "colored",
                        type: "warning",
                        position: "top-center",
                        transition: "slide",
                        dangerouslyHTMLString: true
                    });

                    throw "negado";
                }
            } catch (e) {
                state.sensibilityNum = state.sensibilityLastValue;
            }
        }


        return {
            state,
            status,
            items,
            toogleModal,
            addItem,
            removeItem,
            alertSelected,
            changeSwitch,
            handleSensibility,
            saveRules,
            defineColorByClient,
            translateSensibility,
            availableCategories,
            PROCESSING_OPTIONS,
            ALERT_CATEGORIES,
            RULES_OPTIONS,
            sensibilityToNumber
        };
    },
});

export default ConfigModal;
