import { renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", null, [
      (!_ctx.disableAudit)
        ? (_openBlock(), _createBlock("button", {
            key: 0,
            ref: "buttonRef",
            class: _ctx.classes ? _ctx.classes : _ctx.state.default_classes,
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onButtonClicked && _ctx.onButtonClicked(...args))),
            onBlur: _cache[2] || (_cache[2] = () => _ctx.state.opened = _ctx.state.isHoveringMenu ? _ctx.state.opened : false)
          }, [
            _renderSlot(_ctx.$slots, "default")
          ], 34))
        : (_openBlock(), _createBlock("div", {
            key: 1,
            class: _ctx.classes ? _ctx.classes : _ctx.state.default_classes
          }, [
            _renderSlot(_ctx.$slots, "default")
          ], 2))
    ]),
    (_ctx.state.opened)
      ? (_openBlock(), _createBlock("div", {
          key: 0,
          class: "dropdown origin-top-right z-50 fixed right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none",
          role: "menu",
          "aria-orientation": "vertical",
          "aria-labelledby": "menu-button",
          tabindex: "-1",
          onMouseenter: _cache[4] || (_cache[4] = () => _ctx.state.isHoveringMenu = true),
          onMouseleave: _cache[5] || (_cache[5] = () => _ctx.state.isHoveringMenu = false),
          style: { top: `${_ctx.state.menuListTop}px`, left: `${_ctx.state.menuListLeft}px`}
        }, [
          _createVNode("div", {
            class: "py-1",
            onClick: _cache[3] || (_cache[3] = () => _ctx.state.opened = !_ctx.state.opened)
          }, [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
              return (_openBlock(), _createBlock("a", {
                key: index,
                class: ["text-gray-700 block px-4 py-2 text-sm cursor-pointer dropdown", { 'font-bold': index === _ctx.state.selected} ],
                onClick: () => {
                        _ctx.handleSetOption(index)
                        _ctx.$emit('selected', option.value, _ctx.alertId, _ctx.frameId, _ctx.sessionId)
                    }
              }, _toDisplayString(option.label), 11, ["onClick"]))
            }), 128))
          ])
        ], 36))
      : _createCommentVNode("", true)
  ]))
}