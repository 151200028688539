
import { defineComponent, PropType, reactive, ref, Ref } from 'vue';
import useSignalReceiver from '@/hooks/signalReceiver';

type OptionsType = {
    label: string
    value: string
};

const DropdownActionButton = defineComponent({
    emits: ['selected'],
    props: {
        frameId: {
            type: Number,
            required: false,
        },
        sessionId: {
            type: Number,
            required: false,
        },
        options: {
            type: Object as PropType<OptionsType>,
            required: true,
        },
        classes: {
            type: String,
            required: false
        },
        alertId: Number,
        disableAudit: {
            type: Boolean,
            required: false,
            default: false,
        },
        scrollListenerReceiverId: {
            type: String, 
            default: undefined
        }
    },
    setup(props) {
        const state = reactive({
            opened: false,
            selected: -1,
            default_classes: "dropdown border rounded-full font-medium bg-white px-4 py-2 flex align-start justify-between", 
            isHoveringMenu: false, 
            menuListTop: 0, 
            menuListLeft: 0
        });

        const buttonRef: Ref<any> = ref(null);
        const handleSetOption = (option: number) => {
            state.selected = option;
        };

        const onButtonClicked = () => {
            updateMenuListPosition();
            state.isHoveringMenu = false;
            state.opened = !state.opened;
        };

        const updateMenuListPosition = () => {
            const { top, left, height } = buttonRef.value.getBoundingClientRect();
            state.menuListTop = top + height;
            state.menuListLeft = left;
        };

        let scrollReceiver = undefined;
        if(props.scrollListenerReceiverId){
            scrollReceiver = useSignalReceiver(props.scrollListenerReceiverId);
            scrollReceiver.receive(() => {
                state.opened = false;
            });
        }

        return { 
            handleSetOption, 
            state, 
            onButtonClicked, 
            buttonRef, 
            console, 
            updateMenuListPosition
        };
    }
});

export default DropdownActionButton;
