import { Get, Post, Put, Delete } from './BaseService';
import DisqualificationReasonPostDTO from '@/dtos/DisqualificationReasonPostDTO';
import DisqualificationReasonPutDTO from '@/dtos/DisqualificationReasonPutDTO';
import DisqualificationReasonQuery from '@/queryObjects/DisqualificationReasonQuery';

export default {
    GetAllDisqualificationReason:(dto: DisqualificationReasonQuery) => {
        return Get<any>('api/DisqualificationReason', dto);
    },
    
    PostDisqualificationReason:(dto: DisqualificationReasonPostDTO) => {
        return Post<any, any>('api/DisqualificationReason', dto);
    },

    PutDisqualificationReason:(id: string, dto: DisqualificationReasonPutDTO) => {
        return Put<any, any>(`api/DisqualificationReason/${id}`, dto);
    },

    DeleteDisqualificationReason:(id: string) => {
        return Delete(`api/DisqualificationReason/${id}`);
    }
};