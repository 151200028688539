
import { defineComponent, computed, ref } from "vue";
import Modal from '@/components/Modal.vue';
import Icon from '@/components/Icon.vue';
const BaseAvatar = defineComponent({
    components: { Modal, Icon },
    inheritAttrs: false,
    props: {
        name: {
            type: String,
            default: "",
        },
        color: {
            type: String,
            default: "teal",
        },
        size: {
            type: String,
            default: "md", //sm, md, lg
        },
        round: {
            type: Boolean,
            default: true,
        }
    },

    setup(props) {
        const showPictureModal = ref(false);
        const sizeClasses = computed(() => {
            const sizeMappings: Record<string, string> = {
                sm: `h-12 w-12`,
                md: `h-16 w-16 text-xl`,
                lg: `text-lg h-24 w-24 text-2xl`,
            };
            return sizeMappings[props.size] || sizeMappings.md;
        });
        const roundClasses = computed(() => {
            return props.round ? "rounded-full" : "rounded";
        });
        const avatarClasses = computed(() => {
            return `${props.color} ${roundClasses.value} ${sizeClasses.value}`;
        });
        const avatarText = computed(() => {
            if (!props.name || typeof props.name !== "string" || !props.name.length) {
                return "";
            }
            const split = props.name.split(" ");

            return `${split[0]?.toUpperCase().charAt(0) || ""}${split[1]?.toUpperCase().charAt(0) || ""}`;
        });
        return {
            sizeClasses,
            roundClasses,
            avatarClasses,
            avatarText,
            showPictureModal
        };
    },
});

export default BaseAvatar;
