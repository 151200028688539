
import { PropType, Ref, computed, defineComponent, onMounted, reactive, ref, watch } from "vue";
import Icon from "@/components/Icon.vue";
import BaseAvatar from "@/components/BaseAvatar.vue";
import VideoPlayer from "@/components/VideoPlayer.vue";
import AlertList from "@/components/audit/AlertList.vue";
import vm from "@/viewModels/MainViewModel";
import { maskCpf } from '@/utils/stringFunctions';
import { useAlert } from '@/hooks/alert';
import TakeUserPictureDTO from '@/dtos/TakeUserPictureDTO';
import TextJustificationDTO from "@/dtos/TextJustificationDTO";
import HeaderAudit from "@/components/audit/HeaderAudit.vue";
import PhotosAlertAudit from "@/components/audit/PhotosAlertAudit.vue";
import Loading from "@/components/Loading.vue";
import ExamResponseDTO from "@/dtos/ExamResponseDTO";
import ProctoringResponseDTO from "@/dtos/ProctoringResponseDTO";
import { CandidateDTO } from "@/dtos/CandidateDTO";
import moment from "moment";
import { formatDate, formatTime } from "@/utils/meus-exames/datetime";
import { swapElementClasses } from "@/utils/swapElementClasses";

interface ExamState {
    loading: boolean;
    loadingResponses: boolean;
    photos: TakeUserPictureDTO[];
    errorPage: boolean;
    confirmAudit: boolean;
    justificationText: TextJustificationDTO | null;
    desclassificationModal: boolean;
    viewerComment: string;
    exam: ExamResponseDTO | null;
    container: string;
    selectedPhotoIndex: number;
}

interface ValidBiometricDTO {
    matchDetails: ValidMatchDetailsDTO;
}

interface ValidMatchDetailsDTO {
    face: ValidFaceDTO
}

interface ValidFaceDTO {
    detail: string
}

const MyPhotoAuditView = defineComponent({
    components: {
        Icon,
        avatar: BaseAvatar,
        AlertList,
        VideoPlayer,
        HeaderAudit,
        PhotosAlertAudit,
        Loading
    },
    emits: [
        'statusConfirmScheduling', 
        'statusDeniedScheduling',
        'statusDenyQualifyAudit'
    ],
    props: {
        candidate: {
            type: Object as PropType<CandidateDTO>,
            required: true,
        },
        exam: {
            type: Object as PropType<ExamResponseDTO>,
            required: true,
        },
        audit: {
            type: Object as PropType<ProctoringResponseDTO>,
            required: true,
        },
        loading: {
            type: Boolean
        },
        justificationText: {
            type: Object as PropType<TextJustificationDTO>,
            required: true,
        },
        photos: {
            type: Array as PropType<TakeUserPictureDTO[]>,
            required: true,
        }
    },
    setup(props, context) {

        const alert = useAlert();
        const refs: Ref<(HTMLElement | null)[]> = ref([]);

        const state = reactive<ExamState>({
            loading: false,
            loadingResponses: false,
            photos: [],
            errorPage: false,
            confirmAudit: false,
            justificationText: null,
            desclassificationModal: false,
            viewerComment: "",
            exam: null,
            container: "", 
            selectedPhotoIndex: 0
        });

        const isViewerCoordRole = vm.user?.roleDescription == "VIEWER" || vm.user?.roleDescription == "COORD";
        const isMasterRole = vm.user?.roleDescription == 'MASTER';
        state.container = process.env.VUE_APP_VIDEO_CONTAINER!;
        const userRoleDescription = computed(() => {
            return vm.user?.roleDescription;
        });

        const loadExam = async () => {
            state.loading = true;
            state.justificationText = props.justificationText;
            state.exam = props.exam;
            state.photos = props.photos;
            state.loading = false;
        };

        watch(() => props.loading, () => {
            state.loading = props.loading;
        });

        onMounted(() => {
            loadExam();
        });

        const toggleEvaluation = (viewerStatus: string) => {
            if (viewerStatus == 'reject') {
                state.desclassificationModal = true;
            } else {
                alert({
                    title: "Liberar",
                    message: "Deseja liberar o candidato ?",
                    actions: [{
                        title: "Cancelar"
                    },
                    {
                        action: () => context.emit('statusConfirmScheduling'),
                        title: "Liberar"
                    }
                    ]
                })
            }
        };

        const confirmStatusDeniedScheduling = () => {
            state.desclassificationModal = false;
            context.emit('statusDeniedScheduling', state.viewerComment)
        };

        const confirmStatusDenyQualifyAudit = () => {
            state.desclassificationModal = false;
            context.emit('statusDenyQualifyAudit', state.viewerComment)
        };

        const dropdown = ref();
        const closeModal = (event: any) => {
            if (!!event.path && !event.path[0].className.includes('dropdown') && dropdown.value) {
                dropdown.value.closeModal(event);
            }
        };

        watch(() => props.loading, () => state.loading = props.loading)
        watch(() => props.exam.id, () => loadExam())


        const expandAndCollapseComponent = (value: boolean) => {
            swapElementClasses(value, 'contentPhotoAudit','grid-cols-10', 'grid-cols-11' )
            swapElementClasses(value, 'alertsElement','col-span-4', 'col-span-3' )
            swapElementClasses(value, 'leftBarPhotoAudit','col-span-6', 'col-span-8' )
            swapElementClasses(value, 'gridElement', 'grid-cols-2',  'grid-cols-3' )
        }

        watch(() => vm.hiddenMenu, (newValue: boolean) => expandAndCollapseComponent(newValue));

        onMounted(()=> expandAndCollapseComponent(vm.hiddenMenu))

        const onPhotoClicked = (index: number) => {
            state.selectedPhotoIndex = index;
            !!refs.value[index] && refs.value[index]!.scrollIntoView();
        }

        return {
            toggleEvaluation,
            state,
            maskCpf,
            closeModal,
            dropdown,
            vm,
            userRoleDescription,
            props,
            confirmStatusDeniedScheduling, 
            formatTime, 
            formatDate, 
            moment, 
            onPhotoClicked, 
            refs,
            confirmStatusDenyQualifyAudit,
            isMasterRole, 
            isViewerCoordRole
        };
    },
});

export default MyPhotoAuditView;
