import { renderSlot as _renderSlot, withModifiers as _withModifiers, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = {
  class: "px-1 relative rounded-md bg-white z-10 whitespace-nowrap",
  style: {"box-shadow":"0px 2px 30px rgba(0, 0, 0, 0.15)"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")

  return _withDirectives((_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", {
      onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toogle && _ctx.toogle(...args)), ["stop"]))
    }, [
      _renderSlot(_ctx.$slots, "default", { active: _ctx.active })
    ]),
    _createVNode(_Transition, { name: "showDropdown" }, {
      default: _withCtx(() => [
        (_ctx.active && !_ctx.disabled)
          ? (_openBlock(), _createBlock("div", {
              key: 0,
              class: "absolute z-10",
              style: _ctx.contentPosition,
              onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.closeOnClick ? _ctx.active = false : ''), ["stop"]))
            }, [
              _createVNode("div", _hoisted_2, [
                _renderSlot(_ctx.$slots, "content", { toogle: _ctx.toogle }),
                (_ctx.showArrow)
                  ? (_openBlock(), _createBlock("div", {
                      key: 0,
                      class: "absolute transform rotate-45 rounded-tr-sm w-3 h-3 bg-white",
                      style: _ctx.arrowPosition
                    }, null, 4))
                  : _createCommentVNode("", true)
              ])
            ], 4))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ], 512)), [
    [_directive_click_outside, _ctx.clickOut]
  ])
}