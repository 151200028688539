import { Get, Post } from "./BaseService";

const TwilioService = {
    GetStreams: (user: string, room: string) => {
        return Get<string>(`api/StreamRoom`, {
            identity: user,
            roomName: room
        });
    },
    PostStreams: (examId: string) => {
        return Post<any, any>(`api/StreamRoom/${examId}`, {});
    },
    PostLeaveStreams: (examId: string) => {
        return Post<any, any>(`/api/StreamRoom/leaveRoom/${examId}`, {});
    }
};

export default TwilioService;