
import { computed, defineComponent, reactive } from "vue";
import { useAlert } from "@/hooks/alert";
import vm from "@/viewModels/MainViewModel";

//  Components
import Header from "@/components/Header.vue";
import BaseAvatar from "@/components/BaseAvatar.vue";

import UserDTO from "@/dtos/UserDTO";
import { useRouter } from "vue-router";

const SupportMainView = defineComponent({
    components: { Header, avatar: BaseAvatar, },
    setup() {
        const user = computed<UserDTO>(() => vm.user!);
        const alert = useAlert();
        const { replace } = useRouter();

        const state = reactive({
            businessName: ''
        });

        /** Logout */
        const logout = () => {
            alert({
                icon: "logout",
                title: "Logout",
                message: "Tem certeza que deseja sair do sistema?",
                actions: [
                    {
                        title: "Cancelar",
                        primary: true,
                    },
                    {
                        title: "Sair",
                        action: () => {
                            replace({ name: "login" });
                            vm.logout();
                        },
                    },
                ],
            });
        };

        return {  logout, user, state };
    },
});

export default SupportMainView;
