import { mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "w-full relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")
  const _component_Modal = _resolveComponent("Modal")

  return (_openBlock(), _createBlock("div", {
    class: ["w-6 h-6 flex items-center  justify-center uppercase text-white font-medium shadow", _ctx.avatarClasses],
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showPictureModal = !_ctx.showPictureModal))
  }, [
    (_ctx.$attrs.src)
      ? (_openBlock(), _createBlock("img", _mergeProps({
          key: 0,
          class: ["w-full h-full overflow-hidden", _ctx.roundClasses]
        }, _ctx.$attrs), null, 16))
      : (_openBlock(), _createBlock("span", _hoisted_1, [
          _renderSlot(_ctx.$slots, "default", {}, () => [
            _createTextVNode(_toDisplayString(_ctx.avatarText), 1)
          ])
        ])),
    (_ctx.$attrs.src)
      ? (_openBlock(), _createBlock(_component_Modal, {
          key: 2,
          isOpened: _ctx.showPictureModal,
          canClose: true,
          width: "70%",
          class: "!h-2/4 !w-3/4"
        }, {
          default: _withCtx(() => [
            _createVNode("div", _hoisted_2, [
              _createVNode(_component_Icon, {
                icon: "close",
                color: "#04986D",
                size: "22px",
                class: "absolute top-2 cursor-pointer right-2"
              }),
              (_ctx.$attrs.src)
                ? (_openBlock(), _createBlock("img", _mergeProps({
                    key: 0,
                    class: "w-full rounded-none overflow-hidden px-5 py-7",
                    src: _ctx.$attrs.src.toString()
                  }, _ctx.$attrs), null, 16, ["src"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["isOpened"]))
      : _createCommentVNode("", true)
  ], 2))
}