import AlertListDTO from "@/dtos/AlertListDTO";
import AuditScreenEnum from "@/enums/AuditScreenEnum";

export const isAllAlertsAudited = (alerts: AlertListDTO[]) => {
    return alerts.every(x => x.alertStatus !== "REVIEWING");
}

export const activeBtnToAuditScreenEnum = (activeBtn: string) : AuditScreenEnum => {
    switch (activeBtn) {
        case "QUESTIONS":
            return AuditScreenEnum.QUESTIONS;
        case "CAMERA":
            return AuditScreenEnum.CAMERA;
        case "ENVIRONMENT":
            return AuditScreenEnum.ENVIRONMENT;
        default:
            return AuditScreenEnum.NONE;
    }
}