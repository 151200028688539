
import { defineComponent, reactive } from 'vue';
import SelectStates from "@/components/SelectStates.vue";
import useSignalReceiver from '@/hooks/signalReceiver';

interface StateFlagComponentState {
    selectedUf: string, 
    selectFlag: boolean
}

const StateFlag = defineComponent({
    emits: ['flagChanged'],
    components: {
        SelectStates
    },
    props: {
        flag: {
            type: String, 
            default: ''
        },
        loading: {
            type: Boolean,
            required: false,
        }, 
        parentScrolledEventId: {
            type: String, 
            default: undefined
        }
    },
    setup(props, { emit }) {
        
        const state = reactive<StateFlagComponentState>({
            selectedUf: props.flag,
            selectFlag: props.flag === ''
        });

        const ufChanged = (uf: string, name: string) => {
            state.selectedUf = uf;
            state.selectFlag = false;
            emit('flagChanged', uf, name)
        };
        
        const stateClicked = () => {
            state.selectFlag = false;
        }

        let scrollReceiver = undefined;
        if(props.parentScrolledEventId){
            scrollReceiver = useSignalReceiver(props.parentScrolledEventId);
            scrollReceiver.receive(() => {
                // fechar menu de estados quando for disparado evento de scroll no componente pai
                state.selectFlag = false;
            });
        }
        
        return { state, ufChanged, stateClicked };
    }
});

export default StateFlag;
