
import { defineComponent, onBeforeUnmount, onMounted, onUpdated, reactive, ref } from 'vue';
import * as Video from 'twilio-video';
import Icon from '@/components/Icon.vue';
import TwilioService from '@/services/TwilioService';
import vm from "@/viewModels/MainViewModel";
import { useAlert } from '@/hooks/alert';
import { useRouter } from 'vue-router';
import { trackers } from '@/hooks/insights';

const CandidadesMonitoringView = defineComponent({
    components: { Icon },
    props: {
        examID: {
            type: String,
            required: true
        },
    },
    setup(props) {
        const { replace } = useRouter();
        const alert = useAlert();
        const loading = ref(true);
        const state = reactive({
            twilioToken: "",
            sid: "",
            participants: 0,
        });

        let twilioRoom: Video.Room;


        onMounted(async () => {
            await getToken();//twilio
        });

        onBeforeUnmount(() => {
            const twillioConnected = localStorage.getItem('twillioConnected');
            if (twillioConnected) {
                disconnectAll();
            }
        });

        const getToken = async () => {
            const [request] = await TwilioService.GetStreams(vm.user?.name.split(' ')[0] as string || "admin", props.examID);
            request
                .then(async (resp) => {
                    state.twilioToken = resp;
                    await startVideo();
                });
        };

        const sendOk = () => {
            const [request] = TwilioService.PostStreams(props.examID);
            request.catch((error: any) => {
                if (error == 'Existe um usuário conectado ao stream de video') {
                    setTimeout(() => {
                        if (loading.value == true) {
                            alert({
                                title: "Sala ocupada!",
                                message: "Por favor, tente novamente mais tarde.",
                                actions: [
                                    {
                                        title: "Ok",
                                        primary: true,
                                    }
                                ],
                            });
                        }
                    }, 2000)
                }
            });
        };

        const leaveVideo = () => {
            const [request] = TwilioService.PostLeaveStreams(props.examID);
            request;
        };

        const alertLeaveVideo = () => {
            alert({
                title: "Desconectar vídeo",
                message: "Deseja realmente se desconectar da exibição do vídeo?",
                actions: [
                    {
                        title: "Cancelar",
                        primary: true,
                    },
                    {
                        title: "Desconectar",
                        action: () => {
                            disconnectAll();
                        },
                    },
                ],
            });
        }


        const startVideo = async () => {
            if (state.twilioToken) {
                await Video.connect(state.twilioToken, { name: props.examID, audio: false, video: false }).then(room => {
                    state.sid = room.sid;
                    twilioRoom = room;

                    trackers.registerStreamLog(room.sid, "connected", { message: `Connected to Room ${room.name}` });
                    localStorage.setItem('twillioConnected', room.sid);
                    setInterval(() => {
                        if (state.participants < room.participants.size) {
                            state.participants = room.participants.size;
                            room.participants.forEach((item) => addParticipant(item));
                        } else if (state.participants > room.participants.size) {
                            disconnectAll();

                            trackers.registerStreamLog(room.sid, "stoped", { message: `Disconnected to Room ${room.name}` })

                        }
                        state.participants = room.participants.size;
                    }, 1000);
                });

            }
        };

        sendOk();

        const disconnectAll = () => {
            twilioRoom.disconnect();

            twilioRoom.localParticipant.audioTracks.forEach((item) => {
                item.track.detach();
            });
            twilioRoom.localParticipant.videoTracks.forEach((item) => {
                item.track.detach();
            });
            trackers.registerStreamLog(state.sid, "stoped", { message: `Sala ${twilioRoom.name}, camera e audio desconectada!` })
            localStorage.removeItem('twillioConnected');
            // Exit on API
            leaveVideo();
        };


        const addParticipant = function (participant: any) {
            trackers.registerStreamLog(participant.identity, "connected", { message: `Participant added` })
            const chat = document.querySelector("#remote");
            const trackSubscribed = (track: { attach: () => any; }) => {
                const videoElement = track.attach();
                videoElement.style.width = "99.8%";
                chat!.appendChild(videoElement);
            };
            participant.tracks.forEach((trackPublication: { track: any; }) => {
                if (trackPublication.track) {
                    trackSubscribed(trackPublication.track);
                }
            });
            participant.on("trackSubscribed", trackSubscribed);


            loading.value = false;
        };
        
        onUpdated(() => {
            if (state.participants > 0) {
                loading.value = false;
            }
        });

        return { loading, alertLeaveVideo, disconnectAll };
    }
});

export default CandidadesMonitoringView;
