import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex mx-auto w-full h-10 items-center justify-center ml-50 "
}
const _hoisted_2 = {
  key: 1,
  class: "divide-y  bg-default"
}
const _hoisted_3 = { class: "bg-white flex flex-nowrap h-14 mb-44" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")
  const _component_PhotosAlertList = _resolveComponent("PhotosAlertList")

  return (_ctx.state.loading)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode(_component_Loading, { color: "var(--primary-color)" })
      ]))
    : (_openBlock(), _createBlock("div", _hoisted_2, [
        _createVNode(_component_PhotosAlertList, {
          alerts: _ctx.photos,
          class: "",
          onOnPhotoClicked: _cache[1] || (_cache[1] = (index) => {_ctx.$emit('onPhotoClicked', index)})
        }, null, 8, ["alerts"]),
        _createVNode("div", _hoisted_3, [
          (_ctx.exam.resultStatus != 'DISQUALIFIED' && 
                    _ctx.exam.resultStatus != 'RELEASED' && (
                        _ctx.vm.user?.roleDescription == 'VIEWER' || 
                        _ctx.vm.user?.roleDescription == 'COORD' || 
                        (_ctx.vm.user?.roleDescription == 'MASTER')))
            ? (_openBlock(), _createBlock("button", {
                key: 0,
                class: " rounded-md border-2 border-error bg-white text-error font-medium text-xl w-full h-14 m-4",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('toggleEvaluation', 'reject')))
              }, "Desclassificar"))
            : _createCommentVNode("", true)
        ])
      ]))
}