import { ref, provide } from 'vue';

export default function useSignalSender(signalName: string) {

    const message = ref("");
    const toggle = ref(false);
    provide(signalName, toggle);
    provide(`${signalName}__MESSAGE`, message);

    const send = (msg: (string | undefined) = undefined) => {
        if (msg != undefined)
            message.value = msg;
        toggle.value = !toggle.value;
    };

    return { send };
}