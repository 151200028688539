
import { PropType, defineComponent, reactive, watchEffect } from "vue";
import PhotosAlertList from "@/components/audit/PhotosAlertList.vue";
import vm from '@/viewModels/MainViewModel';
import TakeUserPictureDTO from "@/dtos/TakeUserPictureDTO";
import ExamResponseDTO from "@/dtos/ExamResponseDTO";
import ProctoringResponseDTO from "@/dtos/ProctoringResponseDTO";

interface PhotosAlertAuditDTO {
    loading: boolean
}


const PhotosAlertAudit = defineComponent({
    components: {
        PhotosAlertList
    },
    emits: [
        'toggleEvaluation', 
        'onPhotoClicked'
    ],
    props: {
        photos: {
            type: Array as PropType<TakeUserPictureDTO[]>
        },
        exam: {
            type: Object as PropType<ExamResponseDTO>,
            required: true,
        },
        audit: {
            type: Object as PropType<ProctoringResponseDTO>,
            required: false,
        }
    },
    setup(props, { emit }) {
        const state = reactive<PhotosAlertAuditDTO>({
            loading: false
        });



        return { vm, state };
    }

});

export default PhotosAlertAudit;
