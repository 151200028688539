
import { defineComponent, reactive, watch, computed, PropType } from "vue";
import RadioButton from "@/components/RadioButton.vue";

const StatusFilter = defineComponent({
    components: { RadioButton },
    props: {
        modelValue: {
            type: Number,
            default: undefined
        },
        userType: {
            type: String as PropType<'ADMIN' | 'COORD' | 'VIEWER' | 'SUPPORT' | 'MASTER'>,
            required: true
        },
    },
    emits: ['update:modelValue', 'searchByStatus', 'openCloseFilter'],
    setup(props, context) {
        const filters = reactive({
            selectedStatus: props.modelValue || undefined,
        });

        const status = [
            { title: "Em execução", id: "emexecucao", radioValue: "0", icons: "auditoria", show: "viewer,coord,support,master" },
            { title: "Aguardando Auditoria", id: "emauditoria", radioValue: "1", icons: "auditoria", show: 'admin,support,master' },
            { title: "Em Analise", id: "emanalise", radioValue: "2", icons: "auditoria", show: 'admin,support,master' },
            { title: "Aguardando Confirmação", id: "aguardandoconfirmacao", radioValue: "3", icons: "auditoria", show: "viewer,coord,support,master" },
            { title: "Desclassificado", id: "desclassificado", radioValue: "4", icons: "auditoria", show: "viewer,coord,support,master" },
            { title: "Liberado", id: "liberado", radioValue: "5", icons: "auditoria", show: "viewer,coord,support,master" }
        ];

        //role list ADMIN | VIEWER | COORD | MASTER
        const statusCollection = computed(() => status.filter((st) => st.show.includes(props.userType.toLowerCase())));

        const showResult = () => {
            context.emit('searchByStatus', filters.selectedStatus);
            context.emit('openCloseFilter');
        };

        watch(() => filters.selectedStatus, (newValue) => {
            if (newValue !== undefined) {
                context.emit('update:modelValue', newValue);
            } else {
                clearFilters();
            }
        });

        const clearFilters = () => {
            filters.selectedStatus = undefined;
            context.emit('update:modelValue', undefined);
        };

        return { filters, showResult, clearFilters, statusCollection };
    },
});

export default StatusFilter;
