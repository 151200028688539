
import { defineComponent, PropType, reactive } from "vue";
import Icon from "@/components/Icon.vue";
import { IAlert } from "@/dtos/AlertaDTO";
import DropdownActionButton from "../DropdownActionButton.vue";
import { VALIDATE_ACTIONS_OPTIONS } from "@/components/audit/AlertList.vue";
import { millisToMinutesAndSeconds } from "@/utils/dateTimeFunctions";

const AlertCard = defineComponent({
    components: {
        Icon,
        DropdownActionButton,
    },
    emits: ['check', 'audit'],
    props: {
        proctoringType: String,
        filter: {
            type: String,
            required: true,
        },
        overlay: Boolean,
        alert: {
            type: Object as PropType<IAlert>,
            required: true,
        },
        disableAudit: {
            type: Boolean,
            required: false,
            default: false,
        },
        index: {
            type: Number, 
            required: true
        }, 
        scrollListenerReceiverId: {
            type: String, 
            default: undefined
        }
    },
    setup(props, { emit }) {
        const state = reactive({
            collapsed: true,
        });

        const buttonClasses = (status: string) => {
            const color = getColor(status);
            return `text-white font-medium bg-${color} py-2 px-3`;
        };

        const tradutorAlerta = (
            description:
            | "Manual"
            | "NoFace"
            | "MultipleFace"
            | "OtherFace"
            | "HeadUp"
            | "HeadDown"
            | "HeadRight"
            | "HeadLeft"
            | "HeadRolled"
            | "NoGaze"
            | "GazeUp"
            | "GazeDown"
            | "GazeLeft"
            | "GazeRight"
            | "Audio"
            | "FocusOff"
            | "CellPhone"
            | "OpenMouth"
            | "EnvironmentMultiplePeople"
            | "EnvironmentNoPeople"
            | "ExtractFailed"
            | "ShortExam"
            | "VeryShortExam"
            | "Speech"
            | "NoAlert"
        ) => {
            switch (description) {
                case "Manual":
                    return "Manual";
                case "NoFace":
                    return "Nenhuma face encontrada";
                case "MultipleFace":
                    return "Múltiplas faces encontradas";
                case "OtherFace":
                    return "Face de outra pessoa encontrada";
                case "HeadUp":
                    return "Cabeça para cima";
                case "HeadDown":
                    return "Cabeça para baixo";
                case "HeadRight":
                    return "Cabeça para direita";
                case "HeadLeft":
                    return "Cabeça para esquerda";
                case "HeadRolled":
                    return "Cabeça inclinada";
                case "NoGaze":
                    return "Nenhum olhar detectado";
                case "GazeUp":
                    return "Olhar direcionado para cima";
                case "GazeDown":
                    return "Olhar direcionado para baixo";
                case "GazeLeft":
                    return "Olhar direcionado para esquerda";
                case "GazeRight":
                    return "Olhar direcionado para direita";
                case "Audio":
                    return "Audio encontrado";
                case "FocusOff":
                    return "Perdeu o foco";
                case "CellPhone":
                    return "Celular detectado";
                case "OpenMouth":
                    return "Boca aberta";
                case "EnvironmentMultiplePeople":
                    return "Múltiplas pessoas encontradas";
                case "EnvironmentNoPeople":
                    return "Nenhuma pessoa encontrada";
                case "ExtractFailed":
                    return "Não foi possivel extrair informação biométrica";
                case "ShortExam":
                    return "Duracao do exame abaixo de 15min";
                case "VeryShortExam":
                    return "Duração do exame abaixo de 10min";
                case "Speech":
                    return "Fala detectada: ";
                case "NoAlert":
                    return "Sem alerta";
                default:
                    return description;
            }
        };

        const playButton = async (start: number) => {
            let videoElements = document.querySelectorAll('video');

            const time = start / 1000;
            if (!videoElements) return null;
            for (let i = 0; i < videoElements.length; i++) {
                videoElements[i].currentTime = time;
            }

            if (videoElements[0].paused) document.getElementById('playBtn')?.click();
        };

        const text = (status: string) => {
            switch(status) {
                case "REVIEWING":
                    return 'Não auditado';
                case "RELEASED":
                    return 'Liberado';
                case "BLOCKED":
                    return 'Indício';
                case "SYSTEM_FAILURE":
                    return 'Inválido';
                case "WARNING":
                    return 'Aviso';
            }
        };

        const getColor = (status: string) => {
            switch (status) {
                case "REVIEWING":
                    return '#EDEDED';
                case "RELEASED":
                    return '#04986D33';
                case "BLOCKED":
                    return '#FE938C';
                case "SYSTEM_FAILURE":
                    return '#E8E288';
                case "WARNING":
                    return '#F9F8F9';
            }
        };
        const getColorText = (status: string) => {
            switch (status) {
                case "REVIEWING":
                    return '#787878';
                case "RELEASED":
                    return '#04986D';
                case "BLOCKED":
                    return '#804440';
                case "SYSTEM_FAILURE":
                    return '#66633C';
                case "WARNING":
                    return '#E54F45';
            }
        };

        const handleCollapse = () => {
            state.collapsed = !state.collapsed;
        };

        const handleAudit = (value: string, id: number) => {
            if (props.alert.checked) {
                emit('audit', value, null);
            } else {
                emit('audit', value, id);
            }
        };

        return {
            buttonClasses,
            tradutorAlerta,
            playButton,
            handleCollapse,
            text,
            handleAudit,
            state,
            VALIDATE_ACTIONS_OPTIONS,
            getColor,
            millisToMinutesAndSeconds, 
            getColorText
        };
    },
});

export default AlertCard;
