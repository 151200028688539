enum UserRoleEnum {
    NONE = -1,
    USER = 0,
    ADMIN = 1,
    SUPPORT = 2,
    VIEWER = 4,
    COORD = 5,
    MASTER = 6,
}

function toEnum(userRole: string): UserRoleEnum {
    if (userRole in UserRoleEnum) {
        return UserRoleEnum[userRole as keyof typeof UserRoleEnum];
    } 
    return UserRoleEnum.NONE;
}

export { UserRoleEnum, toEnum };