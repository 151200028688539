
import { computed, defineComponent, ref, watch } from 'vue';
import clickOut from '@/directives/clickOut';
const Dropdown = defineComponent({
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        arrowPadding: {
            type: Number,
            default: 15
        },
        position: {
            type: String,
            default: 'bottom-right'
        },
        closeOnClick: {
            type: Boolean,
            default: true
        },
        closeOnClickOut: {
            type: Boolean,
            default: true
        },
        showArrow: {
            type: Boolean,
            default: true
        }
    },
    directives: {
        "click-outside": clickOut
    },
    setup(props, context) {
        const active = ref(false);

        const toogle = (() => {
            if(!props.disabled) {
                active.value = !active.value;
            }
        });

        const clickOut = () => {
            if(props.closeOnClickOut) {
                active.value = false;
            }
        };

        const contentPosition = computed(() => {
            switch (props.position) {
                case 'top-left':
                    return { bottom: 'calc(100% + 5px)', left: '0' };
                case 'top-right':
                    return { bottom: 'calc(100% + 5px)', right: '0' };
                case 'bottom-left':
                    return { top: 'calc(100% + 5px) ', left: '0' };
                case 'bottom-right':
                    return { top: 'calc(100% + 5px)', right: '0' };
                default:
                    return { left: '0' };
            }
        });

        const arrowPosition = computed(() => {
            switch (props.position) {
                case 'top-left':
                    return { bottom: '-5px', left: props.arrowPadding +'px' };
                case 'top-right':
                    return { bottom: '-5px', right: props.arrowPadding +'px' };
                case 'bottom-left':
                    return { top: '-5px ', left: props.arrowPadding +'px' };
                case 'bottom-right':
                    return { top: '-5px', right: props.arrowPadding +'px' };
                default:
                    return { left: '0' };
            }
        });

        watch(() => active.value, (newValue) => {
            if(!newValue) {
                context.emit('close');
            }
        });

        return { active, toogle, clickOut, contentPosition, arrowPosition };
    }
});

export default Dropdown;
