export enum SensibilityEnum {
    SENSITIVE = 'SENSITIVE',
    REGULAR = 'REGULAR',
    PERMISSIVE = 'PERMISSIVE',
}

export interface ConfigDTO {
    audioBehaviourParameters: {
        restrictiveness: number;
        onlyTranscribedAudio: boolean;
        recordingBitrate: number;
        id: string;
        creationDate: string;
        lastUpdateDate: string;
        deletedFlag: boolean;
        version: string;
    };
    imageBehaviourParameters: {
        frames: number;
        useUploadImage: boolean;
        uploadInterval: number;
        saveVideo: boolean;
        id: string;
        creationDate: string;
        lastUpdateDate: string;
        deletedFlag: boolean;
        version: string;
        sensibility: SensibilityEnum
    };
    videoBehaviourParameters: {
        minAlertTimePitch: number;
        minAlertTimeYaw: number;
        thresholdYaw: number;
        thresholdPitchUp: number;
        thresholdPitchDown: number;
        environmentCamera: boolean;
        useExamAlert: boolean;
        detectPerson: boolean;
        detectFace: boolean;
        detectCellPhone: boolean;
        detectNoise: boolean;
        detectSpeech: boolean;
        showWarningAlerts: boolean;
        id: string;
        creationDate: string;
        deletedFlag: boolean;
        version: string;
    };
}
