
import { defineComponent } from 'vue';
import Icon from '@/components/Icon.vue';

const RadioButton = defineComponent({
    name: 'RadioButton',
    components: {
        Icon
    },
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            type: String,
            required: true
        },
        radioValue: {
            type: String,
            required: true
        },
        name: {
            type: String,
            defaul: ''
        },
        id: {
            type: String,
            require: true
        },
        icon: {
            type: String,
            default: 'auditoria'
        },
        disabled: {
            type: Boolean,
            default: false,
        }

    },
    setup(props, context) {
        const onPressed = () => context.emit('update:modelValue', props.radioValue);
        
        return {
            onPressed
        };
    }
});
export default RadioButton; 
