
import { defineComponent, ref, reactive, onMounted } from "vue";
import { useAlert } from "@/hooks/alert";
import Button from "@/components/Button.vue";
import DisqualificationDTO from "@/dtos/DisqualificationReasonDTO";
import DisqualificationReasonQuery from "@/queryObjects/DisqualificationReasonQuery";
import JustificativaRequestDTO from "@/dtos/DisqualificationReasonPostDTO";
import JustificativaService from "@/services/JustificativaService";
import Loading from "@/components/Loading.vue";
import DisqualificationReasonPutDTO from "@/dtos/DisqualificationReasonPutDTO";
import Icon from "@/components/Icon.vue";

interface DisqualificationState {
    loading: boolean;
    isSaving: boolean;
    isEditing: boolean;
    editingReasonId: string;
    listDisqualifications: DisqualificationDTO[];
    showDeleteButton: boolean;
}

const DisqualificationReasonView = defineComponent({
    components: { Button, Loading, Icon },
    setup() {
        const reason = ref('');
        const summary = ref('');
        const active = ref(true);
        const alert = useAlert();
        const state = reactive<DisqualificationState>({
            loading: false,
            isSaving: false,
            isEditing: false,
            editingReasonId: "",
            listDisqualifications: [],
            showDeleteButton: false
        });

        const clearFields = () => {
            reason.value = "";
            summary.value = "";
            active.value = true;
        };

        const saveReason = async () => {
            state.isSaving = true;
            if (state.isEditing) {
                const dto: DisqualificationReasonPutDTO = {
                    reason: reason.value,
                    summary: summary.value,
                    active: active.value
                };

                const [request] = JustificativaService.PutDisqualificationReason(state.editingReasonId, dto);
                await request
                    .then((resp) => {
                        state.isEditing = false;
                        state.editingReasonId = "";
                        clearFields();
                        loadReasons();
                    })
                    .catch((error) => {
                        createAlert("alert", "Erro edição", error);
                    });
            }
            else {
                const dto: JustificativaRequestDTO = {
                    reason: reason.value,
                    summary: reason.value
                };
                const [request] = JustificativaService.PostDisqualificationReason(dto);
                await request
                    .then((resp) => {
                        clearFields();
                        loadReasons();
                    })
                    .catch((error) => {
                        createAlert("alert", "Erro cadastro", error);
                    });
            }
            state.isSaving = false;
        };

        const deleteReason = (id: string) => {
            createAlert("alert", "Confirmar exclusão", "Tem certeza que deseja excluir essa justificativa?",
                () => {
                    const [request] = JustificativaService.DeleteDisqualificationReason(id);
                    request
                        .then(() => {
                            loadReasons();
                        })
                        .catch((ex) => {
                            alert({
                                title: "Error",
                                message: ex
                            });
                        });
                });
        };

        const editReason = (dto: DisqualificationDTO) => {
            reason.value = dto.reason;
            summary.value = dto.summary;
            active.value = dto.active;
            state.editingReasonId = dto.id;
            state.isEditing = true;
        };

        const createAlert = (icon: string, title: string, message: string, action?: () => void) => {
            let actions: any[];
            if (action == null) {
                actions = [
                    {
                        title: "OK",
                        primary: true
                    }
                ];
            } else {
                actions = [
                    {
                        title: "Cancelar",
                        primary: true
                    },
                    {
                        title: "Confirmar",
                        action: action
                    }
                ];
            }

            alert({
                icon: icon,
                title: title,
                message: message,
                actions: actions
            });
        };

        const loadReasons = () => {
            state.loading = true;
            const dto: DisqualificationReasonQuery = { pageSize: 200 }; //TODO: Em um futuro próximo se necessário implementar um scrool infinito;
            const [request] = JustificativaService.GetAllDisqualificationReason(dto);
            request
                .then((resp) => {
                    state.listDisqualifications = resp.content;
                })
                .catch((error) => {
                    createAlert("alert", "Erro na listagem", error);
                })
                .finally(() => {
                    state.loading = false;
                });
        };

        onMounted(() => {
            loadReasons();
        });

        return {
            saveReason,
            deleteReason,
            editReason,
            loadReasons,
            state,
            reason,
            summary,
            enabled: active
        };
    }
});

export default DisqualificationReasonView;
