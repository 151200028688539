
import { defineComponent } from 'vue';
import FinishIllustration from '@/components/illustrations/FinishIllustration.vue';
import vm from "@/viewModels/MainViewModel";

const MenuPage = defineComponent({
    components: { FinishIllustration },
    
    setup() {
        var env = process.env.NODE_ENV
        /** Redirect */
        const redirect = () => {
            const url = process.env.VUE_APP_BUBBLE_URL + `/admin?token=` + vm.user?.token;
            window.open(url);
        };
       
        return { redirect, env };
    }
});

export default MenuPage;
