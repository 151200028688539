import { createVNode as _createVNode, resolveComponent as _resolveComponent, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "player " }
const _hoisted_2 = { ref: "myPlayer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_alertList = _resolveComponent("alertList")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("video", {
        controls: "",
        class: ["w-full rounded", _ctx.state.isFullScreen ? 'max-h-full' : ' max-h-60vh'],
        ref: "auditVideo",
        src: _ctx.src,
        id: "auditVideo",
        muted: _ctx.state.muted,
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.play && _ctx.play(...args))),
        autobuffer: ""
      }, null, 10, ["src", "muted"]),
      (_ctx.state.isFullScreen)
        ? _withDirectives((_openBlock(), _createBlock(_component_alertList, {
            key: 0,
            alerts: _ctx.alerts,
            id: _ctx.id,
            overlay: true,
            class: "alert-list-overlay bg-gray-200 bg-opacity-70"
          }, null, 8, ["alerts", "id"])), [
            [_vShow, _ctx.state.alertListOpen]
          ])
        : _createCommentVNode("", true)
    ], 512)
  ]))
}