
import { defineComponent, reactive, watchEffect, PropType } from "vue";
import StateFlag from "@/components/StateFlag.vue";
import { titleCase } from '@/utils/stringFunctions';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import Icon from "@/components/Icon.vue";
const SettingFilters = defineComponent({
    emits: ['ufChanged', 'openCloseFilter', 'openClose'],
    components: { StateFlag, Icon },
    props: {
        formattedDateTime: Date,
        closeStatusFilter: {
            type: Boolean,
            required: true
        },
        userType: {
            type: String as PropType<'ADMIN' | 'COORD' | 'VIEWER' | 'SUPPORT' | 'MASTER'>,
            required: true
        },
        filteredUf: {
            type: String,
            default: '',
            required: false
        },
        loading: {
            type: Boolean,
            required: false,
        }
    },
    setup(props, { emit }) {
        const state = reactive({
            selectedDateTemp: '',
            minDateIndex: 0,
            maxDateIndex: 4,
            openTitleCalendar: false,
            openCloseFilter: false,
            year: '',
            dateTime: '',
            filteredUf: props.filteredUf,
            loading: props.loading,
            canFilterState: props.userType ? (props.userType == 'ADMIN' || props.userType == 'MASTER') : false
        });

        watchEffect(() => {
            state.year = props.formattedDateTime ? props.formattedDateTime.getFullYear().toString() : "----";
            const title = titleCase(format(props.formattedDateTime ? props.formattedDateTime : new Date(), "dd'/'LLL'/'YYY", { locale: ptBR }));
            state.dateTime = props.formattedDateTime ? title : "--/--/----";
            state.loading = props.loading;
        });

        const changeUf = (uf: string, name: string) => {
            state.filteredUf = uf;
            emit('ufChanged', uf, name)
        };

        const parentScrolledEventId = "ExamListScrolledEvent";
        return { state, changeUf, parentScrolledEventId };
    }
});
export default SettingFilters;
